import React, { memo, useEffect, useMemo, useState } from 'react';
import useProductContext, { IVariant } from '@/utils/context/product';
import {
  createStyles,
  Group,
  Divider,
  Box,
  keyframes,
  Indicator,
} from '@mantine/core';
import { useRouter } from 'next/router';
import usePageContext from '@/utils/context/page';
import { IPage } from '@/@types/generated/contentful';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import { motion } from 'framer-motion';
import {
  ProductColorPickerProps,
  UiColorPickerProps,
} from '@/interface.custom';
import { useProductInfoStyles } from '../ProductInformation/ProductInformation';

export const blink = keyframes({
  'from, to': { background: '#8597f6' },
  '50%': { background: '#fbfbfb' },
});

const useStyles = createStyles((theme) => ({
  new: {
    borderRadius: '100%',
    animation: `${blink} 2s ease-in-out infinite`,
  },
}));

export const useColorPickerStyles = createStyles((theme) => ({
  color: {
    height: 28,
    width: 28,
    borderRadius: 28,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  active: {
    background: theme.white,
    height: 14,
    width: 14,
    borderRadius: 28,
  },
}));

const UiColorPickers = memo(
  ({
    active,
    variants,
    handleColorChange,
    styles,
  }: UiColorPickerProps & {
    active: string | '';
    variants: IVariant[];
    handleColorChange: (key: string) => void;
  }) => {
    const [hoverColor, setHoverColor] = useState<string | undefined>();
    const [hoverNew, setHoverNew] = useState<boolean | undefined>();

    const { page } = usePageContext();
    const staticContent = useMemo(
      // @ts-ignore
      () => (page as IPage).fields.pageType.staticItems,
      [page],
    );
    const colorName = useMemo(() => {
      const activeColor = variants.find(
        (v) => v?.fields.color?.fields.shopifyKey === active,
      )?.fields.color?.fields.text;
      return hoverColor || activeColor || '';
    }, [active, hoverColor]);

    const { classes } = useColorPickerStyles();
    const productInfoStyles = useProductInfoStyles();

    return variants?.length > 0 ? (
      <Box className={productInfoStyles.classes.infoContentWrapper}>
        <Divider className={styles.smallDivider} />
        <div className={styles.itemWrapper}>
          <NoozText
            color={'title'}
            fw={900}
            size={14}
            mb={16}>
            {staticContent.color}
            <NoozText
              span
              size={14}
              fw={400}
              color={'text'}>
              {` - ${colorName}`}
            </NoozText>
          </NoozText>
          <Group>
            {variants?.map((v, key) => {
              return (
                <Indicator
                  offset={4}
                  size={12}
                  withBorder
                  color={
                    (v.fields.promotion?.fields as any)?.pastilleColor &&
                    !v.fields.new
                      ? (v.fields.promotion?.fields as any)?.pastilleColor
                          .fields.classname
                      : undefined
                  }
                  disabled={
                    !v.fields.new &&
                    !(v.fields.promotion?.fields as any)?.pastilleColor
                  }
                  key={`product_color_${v?.shopifyInfo?.id}_${key}`}>
                  <Box
                    className={classes.color}
                    sx={(theme) => ({
                      background: v.fields.color?.fields.hex,
                      backgroundPosition: 'center',
                      backgroundSize: 'contain',
                      border:
                        v?.fields.color?.fields.shopifyKey === active
                          ? `2px solid ${theme.colors.dark[3]}`
                          : `1px solid ${theme.colors.gray[4]}`,
                    })}
                    onMouseEnter={() => {
                      setHoverColor(v?.fields.color?.fields.text);
                      setHoverNew(v?.fields.new);
                    }}
                    onMouseLeave={() => {
                      setHoverColor(undefined);
                      setHoverNew(false);
                    }}
                    onClick={() => {
                      handleColorChange((v as unknown as { id: string }).id);
                    }}>
                    {v?.fields.color?.fields.shopifyKey === active ? (
                      <motion.div
                        initial={{ opacity: 0, scale: 0 }}
                        animate={{
                          scale: 1,
                          opacity: 1,
                          transition: { ease: 'easeOut', duration: 0.2 },
                        }}
                        exit={{
                          opacity: 0,
                          scale: 0,
                          transition: { ease: 'easeOut', duration: 0.5 },
                        }}>
                        <div className={classes.active} />
                      </motion.div>
                    ) : null}
                  </Box>
                </Indicator>
              );
            })}
          </Group>
        </div>
      </Box>
    ) : null;
  },
);

const ProductColorPicker = ({ styles }: UiColorPickerProps) => {
  const router = useRouter();
  // @ts-ignore
  const { shopifyProduct } = usePageContext();
  const hasColorOption = useMemo(
    () =>
      shopifyProduct?.options.find(({ name }: { name: string }) =>
        ['Color', 'Couleur'].includes(name),
      ),
    [shopifyProduct?.options],
  );
  const { variants, variant, setVariant } =
    useProductContext() as unknown as ProductColorPickerProps;

  const handleColorChange = (value: string) => {
    let newVariant = variants.find(
      (v) => (v as unknown as { id: string }).id === value,
    )!;
    const newQuery: Record<string, any> = {
      color: newVariant.fields.color?.fields.shopifyKey,
    };
    if (typeof window !== 'undefined') {
      const entries = new URLSearchParams(window.location.search).entries();
      // @ts-ignore
      for (const [key, value] of entries) {
        // each 'entry' is a [key, value] tupple
        if (!['locale', 'handles', 'color'].includes(key))
          newQuery[key] = value;
      }
    }
    if (newQuery.diopter) {
      const newShopifyInfo = newVariant?.shopifyVariants?.find(
        (v) =>
          v.selectedOptions.find((s: any) => s.name === 'Diopter')?.value ===
          newQuery.diopter,
      )!;
      newVariant = { ...newVariant, shopifyInfo: newShopifyInfo };
    }
    setVariant(newVariant);
    // @ts-ignore
    if (typeof window !== 'undefined' && window.history?.pushState) {
      var newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        `?${new URLSearchParams(newQuery).toString()}`;
      window.history.replaceState({ path: newurl }, '', newurl);
    }
  };

  const colorVariants = useMemo(() => {
    let cv = [...(variants || [])].filter((v) =>
      v.shopifyInfo.sku.includes(v.fields.title),
    );

    const sizeOpt = variant.shopifyInfo.selectedOptions.find(
      ({ name }: { name: string }) => name === 'Size',
    )?.value;
    if (sizeOpt) {
      cv = cv.filter((v) => {
        let vSize = v.shopifyInfo?.selectedOptions.find(
          ({ name }: { name: string }) => name === 'Size',
        )?.value;
        return vSize === sizeOpt;
      });
    }
    return cv;
  }, [variant, variants]);

  useEffect(() => {
    if (hasColorOption) {
      const path = router.asPath.split('?')[0];
      variants.forEach((v) => {
        const tmp = `${path}?color=${v.fields.color?.fields.shopifyKey}`;
        if (tmp !== router.asPath) router.prefetch(tmp);
      });
    }
  }, []);

  return hasColorOption ? (
    <UiColorPickers
      styles={styles}
      active={variant.fields?.color?.fields.shopifyKey || ''}
      variants={colorVariants}
      handleColorChange={handleColorChange}
    />
  ) : null;
};

export default ProductColorPicker;
