import usePageContext from '@/utils/context/page';
import {
  Box,
  clsx,
  createStyles,
  Divider,
  Flex,
  Group,
  keyframes,
  MediaQuery,
  Title,
  useMantineTheme,
} from '@mantine/core';
import React, { useMemo } from 'react';
import useProductContext from '@/utils/context/product';
import AddToCartButton from '@/components/AddToCartButton/AddToCartButton';
import ProductOptions from '@/components/Product/ProductInformation/ProductInformationOptions';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import { IBlock, IPage, IProduct } from '@/@types/generated/contentful';
import ProductInformationAccordion from '@/components/Product/ProductInformation/ProductInformationAccordion';
import { usePackContext } from '@/utils/context/PackContext';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import useDrawerContext, {
  drawerContextInitState,
} from '@/utils/context/drawer';
import PackOptionSelection from '@/components/PackOptionSelection/PackOptionSelection';
import ProductInformationSticky from './ProductInformationSticky';
import PackProductInfoSticky from '@/components/pages/ProductPack/PackProductInfoSticky';
import {
  ProductColorPickerProps,
  ProductTemplateProps,
} from '@/interface.custom';
import { PRODUCT_LAYOUT_MEDIA_QUERY } from '@/templates/Product';
import useStaticContent from '@/utils/hook/useStaticContent';
import { formatCfItems } from '@/helpers/formatCfItems';
import { useMediaQuery } from '@mantine/hooks';
import { usePackProductStock } from '@/utils/context/PackProductStockContext';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import { APP_INFO } from '@/utils';
import useAuth from '@/utils/context/authentication';
import { Price } from '@/components/Price/Price';

export const marginDesktop = '40px';
export const marginMobile = '16px';

export const slider = keyframes({
  to: { transform: 'translateX(-100%)' },
});

export const useProductInfoStyles = createStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  infoContentWrapper: {
    marginLeft: marginDesktop,
    marginRight: marginDesktop,
    [theme.fn.smallerThan(theme.breakpoints[PRODUCT_LAYOUT_MEDIA_QUERY])]: {
      marginLeft: marginMobile,
      marginRight: marginMobile,
    },
  },
  infoHeaderWrapper: {
    paddingTop: '32px',
    [theme.fn.smallerThan(theme.breakpoints[PRODUCT_LAYOUT_MEDIA_QUERY])]: {
      paddingTop: '24px',
    },
  },
  addToCartWrapper: {
    position: 'sticky',
    zIndex: 1,
    bottom: 0,
    padding: `14px ${marginDesktop}`,
    borderTop: `1px solid ${theme.colors.gray[4]}`,
    alignSelf: 'flex-end',
    backgroundColor: theme.white,
    width: '100%',
    '& .mobile-buttons': {
      display: 'none',
    },
    '& .reassurance': {
      textTransform: 'uppercase',
      fontSize: 10,
      fontWeight: 500,
      textAlign: 'center',
    },
    '& .reassurance-mobile': {
      display: 'none',
    },
    [theme.fn.smallerThan(theme.breakpoints[PRODUCT_LAYOUT_MEDIA_QUERY])]: {
      '&:not(.b2b)': {
        position: 'fixed',
        zIndex: 1001,
        padding: `14px ${marginMobile}`,
        width: '100vw',
        minHeight: 65,
      },
      '& .desktop-buttons': {
        display: 'none',
      },
      '& .mobile-buttons': {
        display: 'flex',
        '& > *:not(:last-of-type)': {
          marginRight: 10,
        },
      },
      '& .reassurance-mobile': {
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
      },
      '& .reassurance-desktop': {
        display: 'none',
      },
    },
  },
  frameFitter: {
    '& .mantine-Modal-modal': {
      width: '75vw',
      [theme.fn.smallerThan(theme.breakpoints.md)]: {
        width: '85vw',
      },
      [theme.fn.smallerThan(theme.breakpoints.sm)]: {
        width: '95vw',
      },
    },
  },
  divider: {
    marginTop: '24px',
    marginBottom: '24px',
  },
  smallDivider: {
    margin: `24px 0`,
    [theme.fn.smallerThan(theme.breakpoints[PRODUCT_LAYOUT_MEDIA_QUERY])]: {
      margin: `24px 0`,
    },
  },
  adWrapper: {
    position: 'relative',
    cursor: 'pointer',
    backgroundColor: '#E1F0F1',
    overflow: 'hidden',
    borderRadius: 18,
    marginTop: 12,
    marginBottom: marginDesktop,
    [theme.fn.largerThan(theme.breakpoints.sm)]: {
      '&: hover': {
        transform: 'scale(1.02)',
      },
    },
    [theme.fn.smallerThan(theme.breakpoints[PRODUCT_LAYOUT_MEDIA_QUERY])]: {
      marginLeft: marginMobile,
      marginRight: marginMobile,
    },
  },
  adText: {
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    bottom: 0,
    height: '100%',
    left: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.fn.smallerThan('xs')]: {
      position: 'relative',
      top: 'unset',
      bottom: 'unset',
      left: 'unset',
    },
    '& > *:not(-:last-of-type)': {
      marginBottom: 10,
    },
  },
  packOptions: {
    margin: `0 ${marginDesktop}`,
    marginBottom: marginDesktop,
    '& .content': {
      display: 'flex',
      marginTop: 18,
      alignItems: 'start',
      '& .text': {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 22,
        justifyContent: 'space-between',
      },
    },
    [theme.fn.smallerThan(theme.breakpoints[PRODUCT_LAYOUT_MEDIA_QUERY])]: {
      margin: `0 ${marginMobile}`,
    },
  },
  reassurance: {
    [theme.fn.largerThan(theme.breakpoints.md)]: {
      transform: 'translateX(100%)',
      animation: `${slider} 9s ease-in-out infinite`,
    },
  },
}));

export const usePackPackOptSelector = () => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const { stocks, hasLoaded } = usePackProductStock();
  const { product } = usePageContext();
  const { pack, setPack } = usePackContext();
  const { setDrawer } = useDrawerContext();
  const updatedStocks = useMemo(() => {
    const updated = { ...stocks };

    for (const opt of Object.values(pack.options)) {
      if (opt) {
        updated[opt.shopifyInfo.id] = updated[opt.shopifyInfo.id] - 1;
      }
    }

    return updated;
  }, [pack, stocks]);

  const openPackOptionSelection =
    (identifier: `option1` | `option2` | `option3`, options: IProduct[]) =>
    () => {
      const { steps } =
        product?.fields.informations?.reduce(
          (prev: Record<string, any>, obj: IBlock) => {
            if (obj.fields.key) prev[obj.fields.key] = obj;
            return prev;
          },
          {},
        ) || {};
      const { label, subLabel } =
        formatCfItems(
          // @ts-ignore
          product?.fields.options.find(
            (opt: Record<string, any>) => opt.key === identifier,
          ).cta,
        ) || {};

      setDrawer({
        ...drawerContextInitState.drawer,
        styles: {
          header: {
            paddingBottom: 8,
          },
          inner: isMobile
            ? {
                height: '100% !important',
                top: '0 !important',
                zIndex: 9000,
              }
            : {},
          body: {
            flex: 1,
          },
        },
        size: 'lg',
        title: (
          <>
            <Flex direction={'column'}>
              <Flex
                justify={'space-between'}
                align={'baseline'}>
                <NoozText
                  sx={(theme) => ({
                    fontSize: 32,
                    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
                      fontSize: 24,
                    },
                  })}
                  fw={900}>
                  {label?.fields.text}
                </NoozText>
              </Flex>
            </Flex>
          </>
        ),
        opened: true,
        body: (
          <PackOptionSelection
            stocks={updatedStocks}
            options={options as unknown as string[]}
            identifier={identifier}
            setPack={setPack}
          />
        ),
      });
    };
  return { openPackOptionSelection, hasLoaded };
};

export const SingleProductInformation = ({
  className,
}: IProductInformation) => {
  const staticContent = useStaticContent();
  const { variant } = useProductContext();
  const auth = useAuth();
  const { classes } = useProductInfoStyles();
  const { shopifyInfo } = variant;

  // const [{ price, compareAtPrice }, setPrices] = useState<Record<string, any>>(
  //   () => ({
  //     price: APP_INFO.type === 'B2B' ? null : shopifyInfo?.price,
  //     compareAtPrice:
  //       APP_INFO.type === 'B2B' ? null : shopifyInfo?.compareAtPrice,
  //   }),
  // );

  // useEffect(() => {
  //   if (APP_INFO.type === 'B2B' && auth.isAuthenticated && !!auth.catalogB2B) {
  //     const prices = auth.catalogB2B[shopifyInfo.sku];
  //     if (prices) {
  //       setPrices({
  //         price: prices.price,
  //         compareAtPrice: prices.compareAtPrice,
  //       });
  //     }
  //   }
  // }, [auth, shopifyInfo]);

  const price = shopifyInfo?.price;
  const compareAtPrice = shopifyInfo?.compareAtPrice;

  const returnActionDesktop = () => {
    return (
      <>
        <Group>
          <Box sx={{ flex: 1 }}>
            <AddToCartButton hasPrice={!!price} />
          </Box>
        </Group>
      </>
    );
  };

  const returnActionMobile = () => {
    return (
      <>
        <AddToCartButton hasPrice />
      </>
    );
  };

  return (
    <ProductInformation
      className={className}
      price={price}
      strikePrice={compareAtPrice}
      actionsDesktop={returnActionDesktop()}
      actionsMobile={returnActionMobile()}>
      <ProductOptions classes={classes} />
    </ProductInformation>
  );
};

const BottomProductInformationPrice = ({ strikePrice, price }: any) => {
  const { variant } = useProductContext() as unknown as ProductColorPickerProps;
  const { page, product } = usePageContext() as unknown as ProductTemplateProps;
  const staticContent = useMemo(
    // @ts-ignore
    () => (page as IPage).fields.pageType.staticItems,
    [page],
  );
  const theme = useMantineTheme();

  const ProductInformationPrice = () => {
    return (
      <Flex
        direction='column'
        justify='left'>
        {price ? (
          <>
            {variant?.fields?.preOrder && (
              <NoozText
                size={10}
                color={'text'}
                transform='uppercase'
                weight={600}>
                {staticContent.preOrderPriceLabel}
              </NoozText>
            )}
            <Price
              price={price}
              discountApply={!!strikePrice}
            />
          </>
        ) : null}
      </Flex>
    );
  };

  const ProductInformationStrikePrice = () => {
    if (strikePrice) {
      return (
        <Flex
          direction='column'
          align='flex-end'>
          {variant?.fields?.preOrder && (
            <NoozText
              size={10}
              color={'text'}
              transform='uppercase'
              weight={600}>
              {staticContent.inStockPriceLabel}
            </NoozText>
          )}
          <Price
            price={strikePrice}
            initialPrice
          />
        </Flex>
      );
    }
    return null;
  };

  return !price ? null : (
    <MediaQuery
      smallerThan={'md'}
      styles={{ display: 'none' }}>
      <Group
        position='apart'
        mb={5}>
        <Flex
          w='100%'
          justify='space-between'>
          <ProductInformationPrice />
          <ProductInformationStrikePrice />
        </Flex>
      </Group>
    </MediaQuery>
  );
};
const TopProductInformationPrice = ({ strikePrice, price }: any) => {
  return !price ? null : (
    <Flex gap={12}>
      <Price
        price={price}
        discountApply={!!strikePrice}
      />
      {strikePrice ? (
        <Price
          price={strikePrice}
          initialPrice
        />
      ) : null}
    </Flex>
  );
};

const ProductInformation = ({
  className,
  price,
  strikePrice,
  children,
  actionsDesktop,
  actionsMobile,
  isPack = false,
}: IProductInformation & {
  price: { amount: string; currencyCode: string };
  strikePrice?: { amount: string; currencyCode: string };
  children?: any;
  actionsDesktop?: any;
  actionsMobile?: any;
  isPack?: boolean;
}) => {
  const theme = useMantineTheme();
  const { classes } = useProductInfoStyles();
  const { page, product, productCategory, breadcrumbs } =
    usePageContext() as unknown as ProductTemplateProps;
  const staticContent = useMemo(
    // @ts-ignore
    () => (page as IPage).fields.pageType.staticItems,
    [page],
  );
  const { variant } = useProductContext() as unknown as ProductColorPickerProps;

  const returnBottomProductInformationLabel = () => {
    if (variant?.fields?.preOrder) {
      return (
        <Flex
          gap={10}
          mt={10}
          justify='center'
          w='100%'>
          <NoozIcon
            size={18}
            color='#1949E7'
            iconKey='TruckDelivery'></NoozIcon>
          <NoozText
            size={12}
            transform='uppercase'
            variant='gradient'
            gradient={{ from: '#1949E7', to: '#50BEFB', deg: 45 }}
            weight={600}>
            {staticContent.preOrderInformationLabel}
          </NoozText>
        </Flex>
      );
    }
  };

  return (
    <>
      {APP_INFO.type === 'B2B' ? null : (
        <>
          {isPack ? (
            <PackProductInfoSticky
              price={price}
              compareAtPrice={strikePrice}
            />
          ) : (
            <ProductInformationSticky price={price} />
          )}
        </>
      )}
      <div className={clsx(classes.wrapper, className)}>
        <div className={classes.infoWrapper}>
          <div
            className={clsx(
              classes.infoHeaderWrapper,
              classes.infoContentWrapper,
            )}>
            <Box
              m={'0 0 18px'}
              sx={{
                [theme.fn.largerThan(theme.breakpoints.md)]: {
                  display: 'none',
                },

                ['& .mantine-Breadcrumbs-root']: {
                  flexWrap: 'wrap',
                },
              }}>
              <Breadcrumbs list={breadcrumbs} />
            </Box>
            <Flex
              w={'100%'}
              justify={'space-between'}
              wrap={'wrap'}
              align={'flex-start'}
              mb={10}>
              <Title
                w={'70%'}
                size={24}
                fw={400}
                ff={'Mont'}
                lh={1}
                order={1}
                color={'title'}>
                <b>{product.fields.label?.fields.text || ''}</b>
                {` - ${productCategory.fields.label?.fields.text}`}
              </Title>
              <TopProductInformationPrice
                price={price}
                strikePrice={strikePrice}
              />
            </Flex>
            <NoozText
              size={16}
              fw={400}
              component={'h2'}
              weight={500}
              mb={0}
              color={'#585858'}>
              {(product.fields.subLabel?.fields.text || '').replace(
                '{{lenses}}',
                (variant.fields as any)?.lense?.fields.label?.fields.text || '',
              )}
            </NoozText>
          </div>
          {children}
          {children?.[1] ? <Divider mb={0} /> : null}
          <ProductInformationAccordion />
        </div>
        <div
          id={'sticky-add-to-cart'}
          className={clsx(classes.addToCartWrapper, {
            b2b: APP_INFO.type === 'B2B',
          })}>
          <BottomProductInformationPrice
            price={price}
            strikePrice={strikePrice}
          />
          <div className={'desktop-buttons'}>{actionsDesktop}</div>
          <div className={'mobile-buttons'}>{actionsMobile}</div>
          {returnBottomProductInformationLabel()}
        </div>
      </div>
    </>
  );
};

export default ProductInformation;
